export const onClientEntry = () => {
  console.log("***** We've started!");
};

export const onInitialClientRender = () => {
  console.log("***** ReactDOM.render has executed");
};

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  console.log("***** change location");
  console.log("      from", prevLocation);
  console.log("      to  ", location);
};
